@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
}

/* Carousel Custom Styling */
.carousel .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .control-dots .dot {
  background: #fff;
  opacity: 0.75;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  background: #007bff;
}

.carousel .carousel-status {
  display: none;
}

/* Text Overlay Styling */
.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.font-bold {
  font-weight: bold;
}

.mb-2,
.mb-4,
.my-4,
.mt-4 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.leading-6,
.leading-8 {
  line-height: 1.5;
}

/* Button Styling */
.bg-blue-600 {
  background-color: #007bff;
}

.bg-blue-300 {
  background-color: #5a9fd4;
}

.text-lg {
  font-size: 1.125rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.px-6,
.py-3,
.px-8,
.py-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.max-w-xs,
.max-w-md {
  max-width: 20rem;
  max-width: 28rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.hover\\:bg-blue-300:hover {
  background-color: #5a9fd4;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.transform {
  transform: scale(1);
}

.hover\\:scale-105:hover {
  transform: scale(1.05);
}

@media (min-width: 640px) {
  .sm\\:text-2xl {
    font-size: 1.5rem;
  }
  .sm\\:text-4xl {
    font-size: 2.25rem;
  }
  .sm\\:max-w-md {
    max-width: 28rem;
  }
}

@media (min-width: 768px) {
  .md\\:text-5xl {
    font-size: 3rem;
  }
  .md\\:leading-8 {
    line-height: 2;
  }
  .md\\:leading-10 {
    line-height: 2.5;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slideIn 1s forwards;
}

.animate-delay-0 {
  animation-delay: 0s;
}

.animate-delay-1 {
  animation-delay: 0.5s;
}

.animate-delay-2 {
  animation-delay: 1s;
}
.custom-border::after {
  content: '';
  display: block;
  position: absolute;
  left: 20%;
  transform: translateX(-50%);
  bottom: -4px; /* Adjust as needed to position the border */
  width: 30%; /* Makes the border half the width of the text */
  height: 4px; /* Thickness of the border */
  background-color: #2563eb; /* Tailwind's blue-600 color */
}

/* src/index.css or src/App.css */
/* body {
  width: 1024px;
  margin: 0 auto;
  overflow-x: hidden;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  body {
    width: 1200px;
  }
} */
